import React from 'react'
import LayoutBlogPage from '../components/layoutBlogPage'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const LoadingFrame = styled('div')`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const loadingContainer = {
  width: '7rem',
  height: '7rem',
  display: 'flex',
  justifyContent: 'space-around',
}

const loadingCircle = {
  display: 'block',
  width: '1.5rem',
  height: '1.5rem',
  backgroundColor: 'black',
  borderRadius: '0.75rem',
}

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const loadingCircleVariants = {
  start: {
    y: '50%',
  },
  end: {
    y: '150%',
  },
}

const loadingCircleTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: 'easeInOut',
}

class BlogPage extends React.Component {
  render() {
    return (
      <LayoutBlogPage>
        <Helmet
          title={'Kidpilot - Latest news'}
          // meta={[
          //   {
          //     name: 'description',
          //     content:
          //       'We create tools for mac, web and mobile that help you focus on what you do best and generally make your life easier.',
          //   },
          //   {
          //     property: 'og:title',
          //     content: 'Kidpilot - We make apps you love to use',
          //   },
          //   { property: 'og:url', content: 'https://wearekidpilot.com/' },
          //   {
          //     property: 'og:description',
          //     content:
          //       'We create tools for mac, web and mobile that help you focus on what you do best and generally make your life easier.',
          //   },
          //   {
          //     property: 'og:image',
          //     content: `https://wearekidpilot.com${ogImage}`,
          //   },
          //   { property: 'og:image:width', content: `1200` },
          //   { property: 'og:image:height', content: `628` },
          // ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="blogPage">
          <LoadingFrame>
            <motion.div
              style={loadingContainer}
              variants={loadingContainerVariants}
              initial="start"
              animate="end"
            >
              <motion.span
                style={{ ...loadingCircle, backgroundColor: '#91aa9d' }}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
              />
              <motion.span
                style={{ ...loadingCircle, backgroundColor: '#fde1c3' }}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
              />
              <motion.span
                style={{ ...loadingCircle, backgroundColor: '#f24c49' }}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
              />
            </motion.div>
          </LoadingFrame>
          <iframe
            height="100%"
            width="100%"
            frameBorder="0"
            src="https://blog.wearekidpilot.com"
            title="Blog"
          />
        </div>
      </LayoutBlogPage>
    )
  }
}

export default BlogPage
